export const countryCodeMap = {
    CZE: "cz",
    SVK: "sk",
   // RUS: "ru",
    UKR: "ua",
    HUN: "hu",
    POL: "pl",
    GER: "de",
    BUL: "bg",
    LTU: "lt",
    SUI: "ch",
    SWE: "se",
    NOR: "no",
    DEN: "dk",
    FIN: "fi",
    EST: "ee",
    LAT: "lv",
    GBR: "gb",
    USA: "us",
    CAN: "ca",
    AUS: "au",
    NZL: "nz",
    JPN: "jp",
    CHN: "cn",
    KOR: "kr",
    ROU: "ro",
    SRB: "rs",
    CRO: "hr",
    SLO: "si",
    AUT: "at",
    FRA: "fr",
    ESP: "es",
    ITA: "it",
  };
  