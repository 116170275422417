<template>
  <v-container>
    <div v-if="alertLoader">
      <!-- Alerty pro admin sdělení -->
      <v-alert
        v-if="showPanelMessageText && panelMessageText"
        title=""
        :text="panelMessageText"
        variant="tonal"
        closable
        @click:close="handleClose('panelMessageText')"
      ></v-alert>

      <v-alert
        type="info"
        v-if="showPanelMessageInfo && panelMessageInfo"
        :text="panelMessageInfo"
        variant="tonal"
        closable
        @click:close="handleClose('panelMessageInfo')"
      ></v-alert>

      <v-alert
        type="warning"
        v-if="showPanelMessageWarning && panelMessageWarning"
        :text="panelMessageWarning"
        variant="tonal"
        closable
        @click:close="handleClose('panelMessageWarning')"
      ></v-alert>
    </div>

    <!-- Alert pro zobrazení zprávy -->
    <v-alert type="success" v-if="successMessage">{{ successMessage }}</v-alert>
    <v-alert type="error" v-if="playgroundUrl">Toto je testovací server. Jeho databáze není spojená s oficiálním serverem. <a href="https://docs.google.com/spreadsheets/d/1JimmEcBN3jaOYIxKDr5WNi-SDgccxBU_8P0yXMuQxNQ/edit?usp=sharing" target="_blank">Report o chybách a vylepšení</a>.</v-alert>

      <v-col cols="12" class="d-flex justify-space-between">
        <router-link v-if="isOrganizer" to="/soutez/vytvorit">
          <v-icon color="green" size="40" title="Vytvořit soutěž">mdi-calendar-plus</v-icon>
        </router-link>

        
        <!-- <v-switch density="compact" v-model="useNewCalendar" label="Kalendář"></v-switch> -->
        <span> </span>
        <v-select 
          :items="availableYears" 
          v-model="selectedYear" 
          density="comfortable" 
          label="Vyberte rok" 
          hide-details="true" 
          style="max-width: 200px;" 
          @change="fetchEvents(selectedYear)"
        >
        </v-select>
      </v-col>

    <h2>Kalendář soutěží:</h2>

    


    <!-- <template v-if="useNewCalendar">
  <v-calendar
    v-model:now="focus"
    :events="eventsForNewCalendar"
    color="primary"
    type="month"
  >
    <template v-slot:event="{ event }">
      <v-card class="ma-2" outlined>
        <v-card-title>
          <router-link :to="'/soutez/' + event.id">{{ event.name }}</router-link>
        </v-card-title>
        <v-card-subtitle>{{ event.club_name }}</v-card-subtitle>
        <v-card-text>{{ event.event_type }}</v-card-text>
      </v-card>
    </template>
  </v-calendar>
</template>


    
<pre v-if="useNewCalendar">{{ eventsForNewCalendar }}</pre> -->

<!-- :loading="loading"
loading-text="Načítám data..." -->


<!-- Skeleton Loader pro tabulku, zobrazí se pouze při načítání -->


    <v-data-table
      density="comfortable"
      :headers="eventHeaders"
      :items="events"
      item-key="id"
      :items-per-page="-1"
      style="border-bottom: thin solid #808080"
      :sort-by="sortBy"
      :row-props="UserHasEntryHighlight" 
      v-model:expanded="expanded"
      show-expand
      :loading="loading"
      loading-text="Načítám data..."
      ref="eventTableRef" 
    >

      <!-- Skeleton Loader -->
      <template v-slot:loading>
        <div v-for="n in 6" :key="n" class="skeleton-row d-flex my-4">
          <v-skeleton-loader class="skeleton-cell mr-2" width="11%" height="24px"></v-skeleton-loader>
          <v-skeleton-loader class="skeleton-cell mr-2" width="38%" height="24px"></v-skeleton-loader> 
          <v-skeleton-loader class="skeleton-cell mr-2" width="3%" height="24px"></v-skeleton-loader> 
          <v-skeleton-loader class="skeleton-cell mr-2" width="28%" height="24px"></v-skeleton-loader> 
          <v-skeleton-loader class="skeleton-cell mr-2" width="6%" height="24px"></v-skeleton-loader> 
          <v-skeleton-loader class="skeleton-cell mr-2" width="3%" height="24px"></v-skeleton-loader> 
          <v-skeleton-loader class="skeleton-cell mr-2" width="8%" height="24px"></v-skeleton-loader> 
        </div>
      </template>

      <template v-slot:no-data>
        <v-alert type="info" class="ma-4">
          Nejsou zde žádné soutěže k zobrazení
        </v-alert>
      </template>
      
      <!-- Datum eventu -->
      <template v-slot:[`item.event_date_start`]="{ item }">
        <div style="text-align: left;" :class="{'canceled-event': item.canceled}"> <!-- Zarovnání obsahu buňky vlevo -->
          <span>{{ $formatDate(item.event_date_start) }}</span>
          <span v-if="item.event_date_end && item.event_date_end !== item.event_date_start" style="white-space: nowrap;">
            &ndash; {{ $formatDate(item.event_date_end) }}
          </span>
        </div>
      </template>

      <!-- Název eventu -->
      <template v-slot:[`item.event_name`]="{ item }">
        <div style="text-align: left;" :class="{'canceled-event': item.canceled }">
          <router-link :to="'/soutez/' + item.id" class="event-name">{{ item.event_name }}</router-link> 
          <v-icon v-if="item.visibility == 'CLUB'" size="small" class="ml-1">mdi-lock-open</v-icon>
        </div>
      </template>

      <!-- Zobrazí expanzní šipku jen pokud item.races má závody -->
      <template v-slot:[`item.data-table-expand`]="{ item }">
        <v-icon v-if="item.races && item.races.length > 0" @click="toggleExpand(item)">
          {{ isExpanded(item) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </template>


      <!-- Typ eventu -->
      <template v-slot:[`item.event_type_display`]="{ item }">
        <div style="overflow: hidden; white-space: nowrap; text-align: left;" :class="{'canceled-event': item.canceled}"> 
          {{ item.event_type_display }}
        </div>
      </template>

      <!-- Klub -->      
      <template v-slot:[`item.club_shortcut`]="{ item }">
        <div style="overflow: hidden; white-space: nowrap; text-align: center;" :class="{'canceled-event': item.canceled}"> 
          {{ item.club_shortcut }}
        </div>
      </template>


      <!-- Počet přihlášek -->
      <template v-slot:[`item.entries`]="{ item }">
        <div style="overflow: hidden; white-space: nowrap;" :class="{'canceled-event': item.canceled}">
          {{ item.entries }}
        </div>
      </template>

      <!-- Odkazy (propozice, pokyny, startovka, výsledky) -->
      <template v-slot:[`item.links`]="{ item }">
        <div style="text-align: left; white-space: nowrap;">
          <router-link :to="`/soutez/${item.id}/prihlaska/`" class="me-2" v-if="item.entry_is_allow">
            <v-icon color="green" size="x-large" title="přihlásit se">mdi-account-plus</v-icon>
          </router-link>
          <a v-if="item.bulletin" :href="`${BaseUrl}${item.bulletin}`" target="_blank">
            <v-icon color="primary" size="x-large" title="propozice">mdi-text-box</v-icon>
          </a>
          <a v-if="item.info" :href="`${BaseUrl}${item.info}`" target="_blank">
            <v-icon color="primary" size="x-large" title="pokyny">mdi-information-slab-box</v-icon>
          </a>
          <a v-if="item.startlist" :href="`${BaseUrl}${item.startlist}`" target="_blank">
            <v-icon color="primary" size="x-large" title="startovka">mdi-timer-play</v-icon>
          </a>
          <a v-if="item.results" :href="`${BaseUrl}${item.results}`" target="_blank">
            <v-icon color="primary" size="x-large" title="výsledky">mdi-trophy</v-icon>
          </a>
        </div>
      </template>
      

      <!-- Zobrazení závodů uvnitř eventu -->
      <template  v-slot:expanded-row="{ columns, item }">
        <td :colspan="columns.length">
          <v-data-table
            density="compact"
            :headers="raceHeaders"
            :items="sortedRaces(item.races)" 
            item-key="id"
            hide-default-footer
            hide-default-header
            hide-no-data
            style="border-bottom: thin solid rgba(var(--v-border-color), var(--v-border-opacity)); color:#808080;"


          >
            <template v-slot:[`item.race_name`]="{ item: race }">
              <div :class="{'canceled-event': item.canceled}" style="text-align: left;">
                {{ race.race_name }}
              </div>
            </template>

            <template v-slot:[`item.race_date`]="{ item: race }">
              <div :class="{'canceled-event': item.canceled}" style="text-align: right;">
                {{ $formatDate(race.race_date) }}
              </div>
            </template>

            <template v-slot:[`item.race_level_display`]="{ item: race }">
              <div :class="{'canceled-event': item.canceled}" style="text-align: left;">
                {{ race.race_level_display }}
              </div>
            </template>

            <template v-slot:[`item.links`]="{ item }">
              <div style="text-align: left;">
                <a v-if="item.bulletin" :href="`${BaseUrl}${item.bulletin}`" target="_blank">
                  <v-icon color="primary" size="x-large" title="propozice">mdi-text-box</v-icon>
                </a>
                <a v-if="item.info" :href="`${BaseUrl}${item.info}`" target="_blank">
                  <v-icon color="primary" size="x-large" title="pokyny">mdi-information-slab-box</v-icon>
                </a>
                <a v-if="item.startlist" :href="`${BaseUrl}${item.startlist}`" target="_blank">
                  <v-icon color="primary" size="x-large" title="startovka">mdi-timer-play</v-icon>
                </a>
                <a v-if="item.results" :href="`${BaseUrl}${item.results}`" target="_blank">
                  <v-icon color="primary" size="x-large" title="výsledky">mdi-trophy</v-icon>
                </a>
              </div>
            </template>
          </v-data-table>
        </td>
      </template>
      <template v-slot:bottom></template>
    </v-data-table>

    <!-- Baner pro zobrazení souhlasu s využitím utils třetích stran -->
    <v-banner v-if="showCookieBanner" rounded="lg" color="warning" icon="mdi-shield-check" style="max-width: 1100px;" class="ma-auto d-flex align-center justify-center">
      <v-banner-text>
        ROBis zlepšujeme díky využití cookies a technologií třetích stran pro analýzu a zlepšení vašeho zážitku při prohlížení webu.
      </v-banner-text>
      <v-banner-actions class="ma-auto d-flex">
        <v-btn @click="acceptCookies">Souhlasím</v-btn>
      </v-banner-actions>
    </v-banner>

    
  </v-container>
</template>

<script setup>
import { ref, onMounted, watch, nextTick} from 'vue';
// import { useDate } from 'vuetify'
import apiClient from '/api.js';
import { useAuthStore } from '/auth.js';


const authStore = useAuthStore();
const loading = ref(true); 

const events = ref([]);
const eventsForNewCalendar = ref([]);

const selectedYear = ref(new Date().getFullYear());
const availableYears = ref([]);
const successMessage = ref('');
const playgroundUrl = ref(false);

const sortBy = [{ key: 'event_date_start', order: 'asc' }]; // Klíč pro řazení



const eventHeaders = ref([
  { title: 'Datum', key: 'event_date_start', align: 'center', sortable: true, width:182 },  
  { title: 'Název soutěže', key: 'event_name', align: 'center', minWidth:170 },
  { title: '', key: 'data-table-expand', align: 'center', width: 50 },
  { title: 'Typ soutěže', key: 'event_type_display', align: 'center', width: 250, maxWidth: 250 },
  { title: 'Pořad.', key: 'club_shortcut', align: 'center', width:90 },
  { title: 'Přihl.', key: 'entries', align: 'center', width:50, sortable: false },
  { title: 'Odkazy', key: 'links', align: 'center', width:160, sortable: false },
]);


const raceHeaders = ref([]);

const expanded = ref([]); // Uchovává expandované řádky

const isExpanded = (item) => {
  return expanded.value.includes(item.id); // Kontrola podle ID
};

const toggleExpand = (item) => {
  if (isExpanded(item)) {
    expanded.value = expanded.value.filter(id => id !== item.id); // Odstranit ID z expandovaných
  } else {
    expanded.value.push(item.id); // Přidat ID do expandovaných
  }
};

// Rozevření races v eventu u blížících se akcí a těsně skončených
const isWithinTimeFrame = (eventDateStart) => {
  const now = new Date();
  const eventDate = new Date(eventDateStart);

  const timeBefore = new Date(now);
  timeBefore.setDate(now.getDate() - 6); // 6 dnů po konci

  const timeAfter = new Date(now);
  timeAfter.setDate(now.getDate() + 3); // 3 dny před začátkem

  // Podmínka, pokud je event_date_start méně než 1 měsíc před nebo méně než 1 měsíc po dnešním datu
  return eventDate >= timeBefore && eventDate <= timeAfter;
};


////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
/////////////////     PANEL PRO ZPRÁVY     /////////////////////////////
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////



const alertLoader = ref(false);

const panelMessageText = ref('');
const panelMessageInfo = ref('');
const panelMessageWarning = ref('');
const showPanelMessageText = ref(true);
const showPanelMessageInfo = ref(true);
const showPanelMessageWarning = ref(true);

const fetchAdminPanelMessages = async () => {
  try {
    const response = await apiClient.get('/admin_message/');
    if (response.data && response.data.length > 0) {
      const messageData = response.data[0];
      panelMessageText.value = messageData.panel_message_text;
      panelMessageInfo.value = messageData.panel_message_info;
      panelMessageWarning.value = messageData.panel_message_warning;

      alertLoader.value = true;
      checkAlertVisibility();
    }
  } catch (error) {
    console.error('Chyba při načítání zpráv:', error);
  }
};

// Kontrola viditelnosti podle času uložení
const checkAlertVisibility = () => {
  const now = new Date().getTime();
  const dayInMs = 24 * 60 * 60 * 1000;

  if (localStorage.getItem('panelMessageTextClosedAt')) {
    const closedAt = parseInt(localStorage.getItem('panelMessageTextClosedAt'));
    showPanelMessageText.value = now - closedAt > dayInMs;
  }

  if (localStorage.getItem('panelMessageInfoClosedAt')) {
    const closedAt = parseInt(localStorage.getItem('panelMessageInfoClosedAt'));
    showPanelMessageInfo.value = now - closedAt > dayInMs;
  }

  if (localStorage.getItem('panelMessageWarningClosedAt')) {
    const closedAt = parseInt(localStorage.getItem('panelMessageWarningClosedAt'));
    showPanelMessageWarning.value = now - closedAt > dayInMs;
  }
};

// Funkce pro zavření upozornění a uložení času do localStorage
const handleClose = (alertType) => {
  const now = new Date().getTime();
  localStorage.setItem(`${alertType}ClosedAt`, now);

  if (alertType === 'panelMessageText') {
    showPanelMessageText.value = false;
  } else if (alertType === 'panelMessageInfo') {
    showPanelMessageInfo.value = false;
  } else if (alertType === 'panelMessageWarning') {
    showPanelMessageWarning.value = false;
  }
};


////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////



const isOrganizer = ref(false);  // Definice proměnné isOrganizer
// Oprávnění pro organizátora k vytváření soutěží

const BaseUrl = ref(process.env.VUE_APP_API_BASE_URL.replace(/\/+$/, '').replace(/\/api\/?$/, '')); // Odstraní "api/" a nadbytečná lomítka

const fetchUserPermissions = async () => {

  if (!authStore.isLoggedIn) {
    console.log('User is not logged in. Skipping permissions fetch.');
    return;
  }

  try {
    const response = await apiClient.get('account/permissions/');
    if (response.status === 200) {
      authStore.updateRoles(response.data.roles); // uložení rolí uživatele do pinia auth sotre
    } else {
      throw new Error('Failed to retrieve permissions');
    }
  } catch (error) {
    console.error('Error retrieving permissions:', error);
  }
};


const fetchEvents = async (year) => {
  try {
    const response = await apiClient.get(`/event/?year=${year}`);
    events.value = response.data;
    // eventsForNewCalendar.value = formatEventsForCalendar(response.data); pro grafický kalendář
    console.log('Formatted events for calendar:', eventsForNewCalendar.value) // Debug log

    if (!availableYears.value.length) {
      const startYear = 2024; // Rok začátku
      const currentYear = new Date().getFullYear();
      availableYears.value = Array.from({length: currentYear + 2 - startYear}, (_, i) => startYear + i);
    }
  } catch (error) {
    console.error('Error fetching events:', error);
  } finally {
    loading.value = false;  // Nastavíme loading na false po načtení dat
  }

};


const sortedRaces = (races) => {
  return races.slice().sort((a, b) => {
    // Seřadit podle race_date
    const dateComparison = new Date(a.race_date) - new Date(b.race_date);
    if (dateComparison !== 0) {
      return dateComparison;
    }
    // Pokud jsou stejné race_date, seřadit podle race_start
    return new Date(a.race_start) - new Date(b.race_start);
  });
};


const eventTableRef = ref(null); 
const totalTitleWidth = ref(0);  // Ref pro celkovou šířku, kterou aplikujeme na druhou tabulku


onMounted(() => {
  fetchEvents(selectedYear.value);
  fetchAdminPanelMessages();
  fetchUserPermissions();

  // updateEvents() pro grafický kalendář
  isOrganizer.value = authStore.roles.includes('organizer');

  watch(events, async () => {
    expanded.value = events.value
      .filter(event => isWithinTimeFrame(event.event_date_start))
      .map(event => event.id);

    await nextTick(); // Počká na vykreslení DOMu

    // Získání šířek sloupců po vykreslení tabulky
    getHeaderWidths();

  // Pokud cookie neexistuje, zobrazí se banner
  showCookieBanner.value = !document.cookie.includes('cookieConsent=true');

  });


  const message = localStorage.getItem('successMessage');
  if (message) {
    successMessage.value = message;
    localStorage.removeItem('successMessage');
  }

  const hostname = window.location.hostname;
  // Podmíněné zobrazení alertu pouze pro specifickou URL
  playgroundUrl.value = (hostname === 'playground.rob-is.cz');
});



const getHeaderWidths = () => {
  nextTick(() => {
    const eventTableElement = eventTableRef.value.$el;
    const headerCells = eventTableElement.querySelectorAll('th');

    if (headerCells.length > 0) {
      const header0Width = headerCells[0].offsetWidth;
      const header1Width = headerCells[1].offsetWidth;
      const header2Width = headerCells[2].offsetWidth;
      const header6Width = headerCells[6].offsetWidth;


      totalTitleWidth.value = header1Width + header2Width; // Nastavení dynamické šířky pro title

      // Ošetření, aby se v Chrome nezobrazoval nezarovnaný sloupec o 1 px
      // const isEdge = /Edg/.test(navigator.userAgent);
      // const isChrome = !isEdge && /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

      // if (isEdge) {
      //   totalTitleWidth.value = (header1Width + header2Width) - 1; // Zmenšení o 1 px pro Edge
      // } else if (isChrome) {
      //   totalTitleWidth.value = (header1Width + header2Width) - 1; // Zmenšení o 1 px pro Chrome
      // } else {
      //   totalTitleWidth.value = header1Width + header2Width; // Standardní šířka pro ostatní prohlížeče
      // }

      
      // Aktualizace raceHeaders s novou hodnotou šířky
      raceHeaders.value = [
        { title: 'Datum', key: 'race_date', align: 'center', sortable: true, width: header0Width },
        { title: 'Název závodu', key: 'race_name', align: 'center', width: totalTitleWidth.value }, // Aktualizace width
        { title: 'Typ závodu', key: 'race_level_display', align: 'center' },
        { title: 'Odkazy', key: 'links', align: 'center', width: header6Width, sortable: false }
      ];
    }
  });
};


watch(selectedYear, (newYear) => {
  fetchEvents(newYear);
});

// Zvýraznění řádku v kalendáři, pokud je uživatel na event přihlášený
const UserHasEntryHighlight = ({ item }) => {
  return item.user_has_entry ? { class: 'user_has_entry' } : {};
};



// Pro grafický kalendář
// const focus = ref(new Date())
// const useNewCalendar = ref(false)

// const updateEvents = async () => {
//   const adapter = useDate()
//   const start = adapter.startOfDay(adapter.startOfMonth(new Date()))
//   const end = adapter.endOfDay(adapter.endOfMonth(new Date()))
//   await fetchEvents(selectedYear.value, start, end)
// }

// const formatEventsForCalendar = (eventsData) => {
//   return eventsData.map((event) => ({
//     name: event.event_name,
//     start: new Date(event.event_date_start), // Convert to Date object
//     end: event.event_date_end ? new Date(event.event_date_end) : new Date(event.event_date_start), // Convert to Date object
//     event_type: event.event_type_display,
//     club_name: event.club_name,
//     id: event.id,
//   }))
// }





const showCookieBanner = ref(false);

// Uložení souhlasu do cookies
const acceptCookies = () => {
  document.cookie = "cookieConsent=true; max-age=31536000; path=/"; // Cookie s platností 1 rok
  showCookieBanner.value = false; // Skryje banner
};


</script>




<style scoped>



  :deep() .v-table .v-table__wrapper > table > thead > tr > th:not(:last-child) {
    border-right: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
    padding: 0 10px !important; 

  }
  :deep() .v-table .v-table__wrapper > table > tbody > tr > td:not(:last-child), .v-table .v-table__wrapper > table > tbody > tr > th:not(:last-child) { 
    border-right: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
    padding: 0 10px !important; 

  }

  /* :deep() table th + th { border-left:10px solid #dddddd; }
  :deep() table td + td { border-left:10px solid #dddddd; } */



  /* Zvýraznění řádku v kalendáři, pokud je uživatel v eventu přihlášený */
:deep(.user_has_entry) {
  background-color: rgba(34, 212, 93, 0.15) !important;
}

.canceled-event {
  text-decoration: line-through;
}


</style>




