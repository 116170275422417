<template>
    <v-select
      v-model="internalValue"
      :items="countries"
      :label="label"
    ></v-select>
  </template>
  
  <script setup>
  import { ref, watch, computed} from "vue";
  import { countryCodeMap } from "@/utils/countryCodes.js";
  
  const props = defineProps({
    modelValue: {
      type: [String, Number, Object],
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: "Země", 
    },
  });
  
// Generovaný seznam zemí (pouze třímístné kódy)
const countries = computed(() => Object.keys(countryCodeMap));

// Interní hodnota pro sledování změn
const internalValue = ref(props.modelValue);
const emit = defineEmits(["update:modelValue"]);

// Synchronizace v-model
watch(
  () => props.modelValue,
  (newValue) => {
    internalValue.value = newValue;
  }
);

watch(
  internalValue,
  (newValue) => {
    emit("update:modelValue", newValue);
  }
);  

</script>