<template>
  <div>

<v-progress-circular
  v-model="progressValue"
  :size="45"
  :width="6"
  color="blue"
  class="cutdown"
>
  {{ countdown }}
</v-progress-circular>

    <!-- Stav načítání -->
    <v-progress-linear indeterminate color="blue" v-if="isLoadingResults" />
    <!-- Zpráva při prázdných výsledcích -->
    <div v-else-if="results.length === 0" class="text-center my-4">
      <p>Pro vybraný závod nejsou dostupné žádné výsledky</p>
    </div>

    <!-- Floating Action Button s menu kategorií -->
    <v-menu v-model="showCategoryMenu" :close-on-content-click="false" offset-y>
  <!-- Aktivátor menu -->
  <template #activator="{ props }">
    <v-btn v-bind="props" class="fab" size="large" elevation="8" color="primary">
      {{ selectedCategory ? selectedCategory : 'Vše' }}
    </v-btn>
  </template>

  <!-- Obsah menu (kategorie) -->
  <v-card style="max-width: 600px;">
    <v-container>
      <v-row>
        <v-col
          cols="4"
          v-for="(categoryName, index) in ['Vše', 'Auto', ...sortedCategoryNames]"
          :key="index"
        >
          <v-btn
            block
            color="primary"
            @click="filterCategory(categoryName)"
            class="category_name_text"
          >
            {{ categoryName }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</v-menu>

    <!-- Výsledky podle kategorií -->
    <v-row v-if="results.length > 0">
      <v-col
        col="12"
        md="12"
        v-for="(categoryResults, categoryName) in filteredResultsByCategory"
        :key="categoryName"
        :id="`category-${categoryName.replace(/\s+/g, '-')}`"
        class="mx-auto px-2"
      >
        <v-row>
          <v-card-title class="mb-2">{{ categoryName }} ({{ categoryResults.length }})</v-card-title>
          <v-spacer></v-spacer>
          <!-- Kontrolní body kategorie -->
          <v-card-subtitle class="d-flex justify-center align-center">
            <strong class="mr-1">Kontroly:</strong>{{ categories.find(category => category.category_name === categoryName)?.category_control_points || "" }}
          </v-card-subtitle>
        </v-row>
        <v-data-table
  :headers="GetResultsHeaders"
  :items="categoryResults"
  :items-per-page="-1"
  class="elevation-2"
  density="comfortable"
  :sort-by="resultsSortBy"
  :custom-sort="customSort"
>
  <template v-slot:item="{ item }">
    <tr
      :class="{ 'highlighted-row': highlightedRows.includes(item.competitor_id) }"
      @click="toggleHighlight(item)"
    >
      <td class="text-center">{{ item.place }}</td>
      <td>
        <router-link v-if="item.account_id" :to="'/ucet/' + item.account_id">
          <CountryFlags :countryCode="item.country" v-if="display.mdAndUp" />
          {{ item.last_name }} {{ item.first_name }}
        </router-link>
        <template v-else>
          <CountryFlags :countryCode="item.country" v-if="display.mdAndUp" />
          {{ item.last_name }} {{ item.first_name }}
        </template>
      </td>
      <td>{{ item.competitor_index }}</td>
      <td v-if="hasStartTimes && item.competitor_start_time">{{ $formatTime(item.competitor_start_time) }}</td>
      <td v-if="hasStartTimes && !item.competitor_start_time"></td>
      <td :class="item.run_time ? 'font-weight-bold' : 'text-grey'">{{ run_time(item) }} <span class="lost_time" v-if="item.lost_time">+{{ item.lost_time }}</span></td>
      <td class="text-center">{{ item.punch_count }}</td>
      <td :style="{
        whiteSpace: display.mdAndUp ? 'normal' : 'nowrap',
        flexWrap: display.mdAndUp ? 'wrap' : 'nowrap',
        maxWidth: display.mdAndUp ? '220px' : 'auto'
      }">
        {{ item.punches.slice(0, -1).map(punch => punch.code).join(", ") }}
      </td>
      <!-- Zobrazení punches -->
      <td>
        <div
          class="d-flex"
          :style="{
            whiteSpace: display.mdAndUp ? 'normal' : 'nowrap',
            flexWrap: display.mdAndUp ? 'wrap' : 'nowrap',
          }"
        >
          <div
            v-for="(punch, index) in item.punches"
            :key="index"
            class="mr-3"
            style="min-width: 52px; text-align: end"
            :class="{ 'text-orange-darken-3': punch.punch_status === 'AP' }"
          >
            <div><b>{{ punch.code }}</b> {{ punch.split_time }}</div>
            <div class="text-muted" style="text-align: right;">
              {{ punch.cumulative_time }}
            </div>
          </div>
        </div>
      </td>
    </tr>
  </template>
  <template v-slot:bottom></template>
</v-data-table>

      </v-col>
    </v-row>
  </div>
</template>



<script setup>
import { ref, watch, computed, onMounted, onUnmounted } from 'vue';
import apiClient from '/api.js';
import { useAuthStore } from '/auth.js';
import { useDisplay } from 'vuetify';
import CountryFlags from '@/components/shared/CountryFlags.vue';


const display = ref(useDisplay());

const props = defineProps({
  raceId: {
    type: Number,
    required: true,
  },
});

const results = ref([]);
const categories = ref([]);
const isLoadingResults = ref(false);

// Výpočet pro kontrolu přítomnosti hodnot `competitor_start_time`
const hasStartTimes = computed(() =>
  results.value.some((item) => item.competitor_start_time)
);

const GetResultsHeaders = computed(() => {
  const headers = [
    { title: "Um.", value: "place", width: "20px", sortable: false },
    { title: "Jméno", value: "last_name", sortable: false },
    { title: "Index", value: "competitor_index", sortable: false },
    { title: "Čas", value: "run_time", sortable: false },
    { title: "Fox", value: "punch_count", width: "50px", align: "center", sortable: false },
    { title: "Kontroly", value: "punch_codes", sortable: false },
    { title: "Mezičasy", value: "punches", sortable: false },
  ];

  if (hasStartTimes.value) {
    headers.splice(3, 0, {
      title: "Start",
      value: "competitor_start_time",
      sortable: false,
    }); // Přidá sloupeček na 4. místo
  }

  return headers;
});

const highlightedRows = ref([]); // Stav pro zabarvené řádky

// Přepnutí stavu zabarvení podle ID
const toggleHighlight = (item) => {
  const index = highlightedRows.value.indexOf(item.competitor_id);
  if (index === -1) {
    highlightedRows.value.push(item.competitor_id); // Přidat ID do seznamu zabarvených
  } else {
    highlightedRows.value.splice(index, 1); // Odebrat ID ze seznamu
  }
};

// Stavy pro menu a vybranou kategorii
const showCategoryMenu = ref(false);
const selectedCategory = ref(null);


const resultsSortBy = [
  { key: "fiktiveOrdering", order: "asc" },
];


// Rozdělení výsledků podle kategorií
const sortedResultsByCategory = computed(() => {
  if (!results.value || results.value.length === 0) {
    return {};
  }

  const grouped = {};
  results.value.forEach((result) => {
    const categoryName = result.competitor_category_name || "Nezařazeni";
    if (!grouped[categoryName]) grouped[categoryName] = [];
    grouped[categoryName].push(result);
  });

  // Seřazení kategorií podle názvu
  const sortedCategories = Object.keys(grouped).sort();
  const sortedGrouped = {};


  sortedCategories.forEach((category) => {
    const sorted = grouped[category].sort((a, b) => {
      // Urči prioritu place: 0 = numerická, 1 = prázdná, 2 = textová
      const getPlacePriority = (x) => {
        if (!isNaN(Number(x.place)) && x.place !== null && x.place !== "") return 0; 
        if (x.place === null || x.place === "") return 1;
        return 2;
      };

      const aPriority = getPlacePriority(a);
      const bPriority = getPlacePriority(b);

      // Nejprve řadíme podle place (numerická → prázdná → textová)
      if (aPriority !== bPriority) {
        return aPriority - bPriority;
      }

      // Pokud je place numerická, porovnej přímo hodnoty
      if (aPriority === 0) {
        return Number(a.place) - Number(b.place);
      }

      // Nyní řadíme podle run_time
      if (a.run_time && b.run_time) {
        const runTimeA = a.run_time.split(":").reduce((acc, time) => 60 * acc + +time, 0);
        const runTimeB = b.run_time.split(":").reduce((acc, time) => 60 * acc + +time, 0);
        return runTimeA - runTimeB;
      } else if (a.run_time && !b.run_time) {
        return -1;
      } else if (!a.run_time && b.run_time) {
        return 1;
      }

      // Nakonec competitor_start_time
      if (a.competitor_start_time && b.competitor_start_time) {
        return new Date(a.competitor_start_time) - new Date(b.competitor_start_time);
      } else if (a.competitor_start_time && !b.competitor_start_time) {
        return -1;
      } else if (!a.competitor_start_time && b.competitor_start_time) {
        return 1;
      }

      return 0;
    });

      sortedGrouped[category] = sorted;
    });

    return sortedGrouped;
  });



// Filtrované výsledky podle vybrané kategorie
const filteredResultsByCategory = computed(() => {
  if (!selectedCategory.value) {
    return sortedResultsByCategory.value;
  }
  return {
    [selectedCategory.value]: sortedResultsByCategory.value[selectedCategory.value],
  };
});

const authStore = useAuthStore();

// Funkce pro filtrování podle kategorie
const filterCategory = (categoryName) => {
  showCategoryMenu.value = false;
  if (categoryName === 'Auto') {
    toggleAutoMode(); // Aktivace režimu Auto
  } else {
    if (autoModeActive.value) {
      disableAutoMode(); // Deaktivace režimu Auto
    }
    selectedCategory.value = categoryName === 'Vše' ? null : categoryName;
    showCategoryMenu.value = false;
  }
};


const sortedCategoryNames = computed(() => {
  const categoryNames = Object.keys(sortedResultsByCategory.value);
  categoryNames.sort(); // Seřazení abecedně
  return categoryNames; // Přidání položky "Vše" na začátek
});


// Funkce pro načítání výsledků z API
const fetchResults = async () => {
  if (!props.raceId) return;
  isLoadingResults.value = true;
  try {
    const response = await apiClient.get(`/results/?race_id=${props.raceId}&all=true`);
    categories.value = response.data.categories || [];
    results.value = response.data.results || [];
  } catch (error) {
    console.error("Chyba při načítání dat:", error);
    results.value = [];
    categories.value = [];
  } finally {
    isLoadingResults.value = false;
  }
};

const autoRefreshInterval = ref(null);
const countdown = ref(10); // Stav pro odpočítávání
const progressValue = ref(100); // Stav pro procentuální hodnotu


// Automatické načítání v intervalech
const startAutoRefresh = () => {

  autoRefreshInterval.value = setInterval(() => {
    fetchResults(); // načtení da v intervalu 10 sekund
    // if (document.visibilityState === "visible") {
    //   fetchResults(); // Načítá data pouze, když je stránka viditelná
    // }
    countdown.value = 11; // Reset odpočítávání
    progressValue.value = 100; // Reset procentuálního stavu

    // Přepnutí na další kategorii v režimu Auto
    if (autoModeActive.value && sortedCategoryNames.value.length > 1) {
      currentCategoryIndex = (currentCategoryIndex + 1) % sortedCategoryNames.value.length;
      const nextCategory = sortedCategoryNames.value[currentCategoryIndex];
      selectedCategory.value = nextCategory;
    }


  }, 10000);

  // Odpočítávání a aktualizace procent každou sekundu
  const countdownInterval = setInterval(() => {
    if (countdown.value > 0) {
      countdown.value -= 1;
      progressValue.value = (countdown.value / 11) * 100; // Přepočítání na procenta
    }
  }, 1000);

  // Uloží oba intervaly pro zastavení
  autoRefreshInterval.value = {
    interval: autoRefreshInterval.value,
    countdownInterval: countdownInterval,
  };
};

// Zastavení automatického načítání
const stopAutoRefresh = () => {
  if (autoRefreshInterval.value) {
    clearInterval(autoRefreshInterval.value.interval);
    clearInterval(autoRefreshInterval.value.countdownInterval);
    autoRefreshInterval.value = null;
  }
};

let autoModeActive = ref(false); // Stav pro režim Auto

// Sledování změny závodu
watch(
  () => props.raceId,
  async (newRaceId) => {
    if (!newRaceId) return;
    selectedCategory.value = null; // Reset vybrané kategorie na "Vše"

    // Vypnutí automatického režimu, pokud je aktivní
    if (autoModeActive.value) {
      autoModeActive.value = false;
      disableAutoMode(); // Vypne fullscreen režim
    }

    await fetchResults(); // Načti nová data při změně závodu
    
    // Pokud nějaký results account_id odpovídá id přihlášeného uživatele, zobrazíme jeho kategorii
    const userResult = results.value.find(
        (item) => item.account_id === Number(authStore.userID)
      );
      if (userResult) {
        selectedCategory.value = userResult.competitor_category_name;
      }
    },
  { immediate: true }
);

// Nastavení a zrušení intervalu při připojení a odpojení komponenty
onMounted(() => {
  startAutoRefresh(); // Spusť interval

  // Aktualizace dynamických časů každou sekundu
  setInterval(() => {
    results.value.forEach((item) => {
      if (!item.run_time && item.competitor_start_time) {
        item.dynamic_time = run_time(item);
      }
    });
  }, 1000);
});


onUnmounted(() => {
  stopAutoRefresh(); // Zastav interval při odpojení
  if (autoModeActive.value) {
      disableAutoMode(); // Deaktivace režimu Auto
    }
});



let currentCategoryIndex = 0; // Index aktuální kategorie

// Aktivace režimu Auto
const toggleAutoMode = () => {
  autoModeActive.value = true;
  currentCategoryIndex = 0; // Začněte od první kategorie

  if (sortedCategoryNames.value.length > 0) {
    // Nastav první kategorii
    const firstCategory = sortedCategoryNames.value[currentCategoryIndex];
    selectedCategory.value = firstCategory;
  }
  enterFullscreen();
};

// Deaktivace režimu Auto
const disableAutoMode = () => {
  autoModeActive.value = false;
  exitFullscreen();
};

const enterFullscreen = () => {
  const docElement = document.documentElement;
  if (docElement.requestFullscreen) {
    docElement.requestFullscreen();
  } else if (docElement.webkitRequestFullscreen) { // Safari
    docElement.webkitRequestFullscreen();
  } else if (docElement.msRequestFullscreen) { // IE11
    docElement.msRequestFullscreen();
  }
};

const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) { // Safari
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) { // IE11
    document.msExitFullscreen();
  }
};



// Dynamický čas pro položky bez `run_time`
const run_time = (item) => {
  if (item.run_time) {
    return item.run_time; // Pokud je `run_time`, zobraz jej
  }
  if (!item.competitor_start_time) {
    return ""; // Pokud není čas startu, zobraz prázdnou hodnotu
  }

  const startTime = new Date(item.competitor_start_time);
  const currentTime = new Date();
  
  // Kontrola, zda je aktuální datum stejné jako datum startu
  const isSameDate =
    startTime.toISOString().slice(0, 10) === currentTime.toISOString().slice(0, 10);

  if (!isSameDate) {
    return ""; // Zobraz nci, pokud datum neodpovídá
  }

  const elapsedSeconds = Math.floor((currentTime.getTime() - startTime.getTime()) / 1000);
  if (elapsedSeconds < 0) {
    return "--:--"; // Zobraz, pokud je čas v budoucnosti
  }

  const minutes = Math.floor(elapsedSeconds / 60);
  const seconds = elapsedSeconds % 60;

  return `${minutes}:${seconds.toString().padStart(2, "0")}`;
};


</script>



<style scoped>
.fab {
  position: fixed;
  bottom: 26px;
  right: 22px;
  z-index: 1000;
  font-size: 15px;
  padding: 8px 28px;
}

.category_name_text {
  white-space: nowrap; /* Zabrání zalomení textu na nový řádek */
  overflow: hidden;    /* Skryje přetékající text */
  text-overflow: ellipsis; /* Přidá tři tečky na konci přetékajícího textu */
  max-width: 100%; /* Zajistí, že text nepřekročí šířku tlačítka */
}

.cutdown {
  position: fixed;
  bottom: 26px;
  left: 18px;
  z-index: 1000;
}


.highlighted-row {
  background-color: #e3f2fd; /* Světle modrá barva */
}

/* Přepsání paddingu na 8px pro všechny buňky v tabulce (tělo i hlavička) */
.v-data-table > .v-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-table__wrapper > table > thead > tr > td,
.v-data-table > .v-table__wrapper > table > thead > tr > th,
.v-data-table > .v-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-table__wrapper > table > tfoot > tr > th {
  padding: 0 8px !important; 
}

:deep().v-table > .v-table__wrapper > table > thead > tr > th {
  padding: 0 8px !important;
}

.lost_time {
  color:gray; 
  font-size: 11px; 
  font-weight: lighter;
}

</style>