<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-form ref="formRef" @submit.prevent="submitRole">
      <v-card-title>
        {{ mode === 'edit' 
          ? (roleType === 'referee' ? 'Upravit rozhodčího' : 'Upravit trenéra')
          : 'Vytvořit novou roli' }}
      </v-card-title>
      <v-radio-group hide-details v-model="mode" inline class="mx-8">
        <v-radio value="edit" color="primary" label="Upravit stávající roli">
          <template #label>
            <v-icon color="primary">mdi-pencil</v-icon>
            Uprav stávající roli
          </template>
        </v-radio>
        <v-spacer></v-spacer>
        <v-radio value="create" color="success">
          <template #label>
            <v-icon color="success">mdi-plus-thick</v-icon>
            Vytvořit novou roli
          </template>
        </v-radio>
      </v-radio-group>
      <v-card-text>
        <v-select
          v-model="roleType"
          :items="roleTypeOptions"
          item-title="text"
          item-value="value"
          label="Vyberte roli"
          :rules="[requiredRule]"
        ></v-select>
        <v-select
          v-model="roleData.lvl"
          :items="availableLvlOptions"
          item-title="text"
          item-value="value"
          label="Vyber třídu"
          :disabled = "mode !== 'create'"
          :rules="[requiredRule]"
        ></v-select>
        <v-text-field
          v-model="roleData.valid_from" 
          label="Platnost od"
          :disabled = "mode !== 'create'"
          type="date"
          :rules="[requiredRule]"
        ></v-text-field>
        <v-text-field
          v-model="roleData.valid_to" 
          label="Platnost do"
          type="date"
          :rules="[requiredRule]"
        ></v-text-field>
        <v-textarea 
          v-model="roleData.description" 
          maxlength="50" 
          counter 
          rows="1" 
          auto-grow="true"
          label="Poznámka"
        ></v-textarea>
        <v-alert type="error" v-if="errorMessage">{{ errorMessage }}</v-alert>

      </v-card-text>

      <v-card-actions>
        <v-btn text color="grey" @click="closeDialog">Zrušit</v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="error" v-if="mode == 'edit'" @click="confirmDeleteDialog = true">Smazat</v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="green" :disabled="isSubmitting" @click="submitRole">
          {{ mode === 'create' ? 'Vytvořit' : 'Uložit'}}
        </v-btn>
      </v-card-actions>
    </v-form>
    </v-card>
  </v-dialog>

  <v-dialog v-model="confirmDeleteDialog" max-width="400px">
  <v-card>
    <v-card-title class="text-h6">Opravdu chcete tuto roli smazat?</v-card-title>
    <v-card-actions>
      <v-btn text color="grey" @click="confirmDeleteDialog = false">Zrušit</v-btn>
      <v-spacer></v-spacer>
      <v-btn text color="error" @click="deleteRole" type="submit">Smazat</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

<Snackbar
  v-model="successSnackbar"
  :message="successMessage"
/>


</template>




<script setup>
import { ref, watch, computed } from 'vue';
import apiClient from '/api.js';
import Snackbar from './shared/SuccessSnackbar.vue'


const props = defineProps({
  modelValue: Boolean,
  member: Object, // Předává uživatele při úpravě
});

const emit = defineEmits(['update:modelValue', 'role-saved']);

const mode = ref('edit'); // Výchozí režim je 'edit'
const dialog = ref(false);

const formRef = ref(null); // Reference na formulář
const requiredRule = (value) => !!value || 'Toto pole je povinné.';

const roleTypeOptions = [
  { value: 'referee', text: 'Rozhodčí' },
  { value: 'coach', text: 'Trenér' },
];

const roleLvlOptions = [
  { value: '1', text: 'I. třída' },
  { value: '2', text: 'II. třída' },
  { value: '3', text: 'III. třída' },
];

const roleType = ref('referee');
const roleData = ref({
  lvl: '',
  valid_from: null,
  valid_to: null,
  description: '',
});

// Dynamické dostupné třídy
const availableLvlOptions = computed(() => {
  if (mode.value === 'create' && props.member) {
    const existingLvl = props.member[roleType.value]?.lvl || '';
    return roleLvlOptions.filter((lvl) => lvl.value !== existingLvl);
  }
  return roleLvlOptions;
});

// Nastavení prázdných dat
const resetRoleData = () => {
  roleData.value = {
    lvl: '',
    valid_from: null,
    valid_to: null,
    description: '',
  };
};

// Nastavení dat podle uživatele a role
const setRoleData = (item, roleType) => {
  const role = item[roleType] || {
    lvl: '',
    valid_from: null,
    valid_to: null,
    description: '',
  };

  return {
    ...role,
  };
};

// Synchronizace při otevření dialogu
watch(
  () => props.modelValue,
  (newVal) => {
    dialog.value = newVal;

    if (newVal) {
      if (mode.value === 'edit' && props.member) {
        roleType.value = props.member.referee ? 'referee' : 'coach';
        roleData.value = setRoleData(props.member, roleType.value);
      } else if (mode.value === 'create') {
        resetRoleData(); // Resetujeme pole při vytvoření
      }
    }
  }
);

// Sledujeme změny režimu
watch(mode, (newMode) => {
  if (newMode === 'create') {
    resetRoleData();
  } else if (newMode === 'edit' && props.member) {
    roleData.value = setRoleData(props.member, roleType.value);
  }
});

// Synchronizace změn roleType
watch(roleType, (newRoleType) => {
  if (mode.value === 'edit' && props.member) {
    roleData.value = setRoleData(props.member, newRoleType);
  }
});

// Synchronizace `modelValue` s dialogem
watch(dialog, (newVal) => {
  emit('update:modelValue', newVal);
});


// Sledujeme změny platnosti od a typu role
watch(
  () => roleData.value.valid_from,
  (newValidFrom) => {
    if (newValidFrom && mode.value === 'create') {
      const yearsToAdd = roleType.value === 'coach' ? 3 : 5;

      // Vždy aktualizujeme valid_to při změně valid_from
      const validFromDate = new Date(newValidFrom);
      const validToDate = new Date(validFromDate);
      validToDate.setFullYear(validFromDate.getFullYear() + yearsToAdd);
      // odečteme od konečného data -1 den
      validToDate.setDate(validToDate.getDate() - 1);

      // Převod zpět na `yyyy-mm-dd`
      roleData.value.valid_to = validToDate.toISOString().split('T')[0];
    }
  }
);


const errorMessage = ref ("");
const successSnackbar = ref(false);
const successMessage = ref('');
const isSubmitting = ref(false);


// Odeslání formuláře
const submitRole = async () => {

  // Validace formuláře, jestli jsou vyplněná povinná pole
  if (formRef.value && !formRef.value.validate()) {
    return; // Zastav odesílání, pokud validace selhala

  }
  const validateForm = () => {
    const isValid =
      !!roleType.value &&
      !!roleData.value.lvl &&
      !!roleData.value.valid_from &&
      !!roleData.value.valid_to;

    return isValid;
  };

  if (!validateForm()) {
      return; // Zastav odesílání
    }

  try {
    const payload = {
      role_type: roleType.value,
      ...roleData.value,
    };

    if (mode.value === 'create') {
      await apiClient.post(`account/role/?account_id=${props.member.id}`, payload);
      successMessage.value = 'Záznam byl úspěšně vytvořen';
    } else {
      await apiClient.put(`account/role/?account_id=${props.member.id}`, payload);
      successMessage.value = 'Záznam byl úspěšně aktualizován!'
    }

    emit('role-saved');
    closeDialog();
    errorMessage.value = ""
    successSnackbar.value = true;
  } catch (error) {
    if (error.response) {
      const status = error.response.status;
      const detail = error.response.data.detail || 'Neznámá chyba.';

      if (status === 406 || status === 409) {
        errorMessage.value = detail;
      } else {
        console.error(`Neočekávaná chyba: ${status}`, detail);
        errorMessage.value = "Data se nepodařilo odeslat";
      }
    } else {
      console.error('Chyba při komunikaci s API:', error.message);
      errorMessage.value = "Data se nepodařilo odeslat";
    }
  }
};


const closeDialog = () => {
  dialog.value = false;
};


const confirmDeleteDialog = ref(false); // Stav potvrzovacího dialogu

const deleteRole = async () => {
  try {
    const payload = { role_type: roleType.value };

    // Odeslání požadavku DELETE na API
    await apiClient.delete(`account/role/?account_id=${props.member.id}`, {
      data: payload,
    });

    successMessage.value = 'Role byla úspěšně smazána.';
    successSnackbar.value = true;
    confirmDeleteDialog.value = false; // Zavření potvrzovacího dialogu
    closeDialog(); // Zavření hlavního dialogu
    emit('role-saved'); // Emitujeme událost o smazání
    errorMessage.value = "";
  } catch (error) {
    const detail = error.response.data.detail || 'Neznámá chyba.';
    console.error('Chyba při mazání role:', error.message);
    errorMessage.value = `Nepodařilo se smazat roli. ${detail}`;
    confirmDeleteDialog.value = false; // Zavření potvrzovacího dialogu
  }
};

</script>


<style scoped>




</style>