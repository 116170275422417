<template>
  <v-snackbar
    v-model="successSnackbar"
    timeout="5000"
    color="success"
    top
  >
    {{ message }}
  </v-snackbar>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  modelValue: Boolean,
  message: {
    type: String,
    required: true,
  },
});


const emit = defineEmits(['update:modelValue']);

const successSnackbar = ref(props.modelValue);

watch(
  () => props.modelValue,
  (newVal) => {
    successSnackbar.value = newVal;
  }
);

watch(successSnackbar, (newVal) => {
  emit('update:modelValue', newVal);
});
</script>

