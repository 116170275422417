import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '/auth.js';
import Home from '../views/HomePage.vue';
import Register from '../views/RegisterPage.vue';
import Login from '../views/LoginPage.vue';
import Account from '../views/AccountPage.vue';
import AccountPayment from '../views/AccountPayment.vue';
import AccountSettings from '../views/AccountSettings.vue';
import PasswordReset from '@/views/PasswordReset.vue';
import AllMembers from '../views/MembersPage.vue';

import NotFound from "@/views/NotFound.vue";



import Event from '../views/EventPage.vue';
import EventCreate from '../views/EventCreate.vue';
import EventSettings from '../views/EventSettings.vue';
import EventEntry from '../views/EntryPage.vue';


import AllClub from '../views/AllClubPage.vue';
import Club from '../views/ClubPage.vue';
import Help from '../views/HelpPage.vue';
import Exporty from '../views/ExportPage.vue';
import Api from '../views/ApiPage.vue';
import Version from '../views/VersionPage.vue';
import ResultsPage from '@/views/ResultsPage.vue';




const routes = [
  {path: '/', name: 'HomePage', component: Home},
  {path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound},


  {path: '/registrace', name: 'RegisterPage', component: Register},
  {path: '/login', name: 'LoginPage', component: Login},
  {path: '/reset_hesla/:uid/:token', name: 'PasswordReset', component: PasswordReset, props: true },
  {path: '/ucet/:id/', name: 'AccountPage', component: Account},
  {path: '/finance/', name: 'AccountPayment', component: AccountPayment, meta: { requiresAuth: true }},
  {path: '/nastaveni/', name: 'AccountSettings', component: AccountSettings, meta: { requiresAuth: true }},
  {path: '/clenove/', name: 'AllMembers', component: AllMembers, meta: { requiresAuth: true }},
  {path: '/vysledky/', name: 'ResultsPage', component: ResultsPage},

 



  {path: '/soutez/vytvorit', name: 'EventCreate', component: EventCreate, meta: { requiresAuth: true }},
  {path: '/soutez/:id', name: 'EventPage', component: Event},
  {path: '/soutez/:id/nastaveni', name: 'EventSettings', component: EventSettings, meta: { requiresAuth: true }},
  {path: '/soutez/:id/prihlaska/', name: 'EventEntry', component: EventEntry},


  
  {path: '/oddil', name: 'AllClubPage', component: AllClub},
  {path: '/oddil/:id', name: 'ClubPage', component: Club, meta: { requiresAuth: true }},
  {path: '/napoveda', name: 'HelpPage', component: Help},
  {path: '/export', name: 'ExportPage', component: Exporty, meta: { requiresAuth: true }},
  {path: '/api', name: 'ApiPage', component: Api, meta: { requiresAuth: true }},
  {path: '/verze', name: 'VersionPage', component: Version},




];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Pokud cílová stránka je HelpPage, nebudeme měnit pozici scrollování
    if (to.name === 'HelpPage') {
      return;
    }
    // Pro všechny ostatní stránky se bude scrollovat nahoru
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0, behavior: 'smooth' };
    }
  },
});

// Před načtením se zkontrolují položky, které vyžadují autorizaci
router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  if (to.matched.some(record => record.meta.requiresAuth) && !authStore.isLoggedIn) {
    // Uživatel není přihlášen a pokouší se dostat na stránku, která vyžaduje autentizaci
    next({ name: 'LoginPage', query: { redirect: to.fullPath } }); // Přesměrování na stránku pro přihlášení
  } else {
    // V jiných případech pokračovat v navigaci
    next();
  }
});


export default router;