<template>
    <img
      v-if="twoLetterCode"
      :src="`https://flagcdn.com/h40/${twoLetterCode}.png`"
      :alt="`Vlajka ${countryCode}`"
      class="flag-icon"
    />
</template>
  
  <script setup>
  import { computed } from "vue";
  import { countryCodeMap } from "@/utils/countryCodes.js";

  
  const props = defineProps({
  countryCode: {
    type: String,
    required: true, // Třípísmenný kód země
  },
});
  
  // Převod třípísmenného kódu na dvoupísmenný
  const convertToTwoLetterCode = (threeLetterCode) => {
    return countryCodeMap[threeLetterCode] || null;
  };
  
  // Vypočítaný kód pro vlajku
const twoLetterCode = computed(() => convertToTwoLetterCode(props.countryCode));
</script>
  
  <style scoped>


  .flag-icon {
    width: 23px; /* Nastavte požadovanou šířku vlaječky */
    height: auto;
    margin-right: 2px; /* Vzdálenost mezi vlaječkou a textem */
    margin-top: -2px;
    border: 1px solid rgb(236, 236, 236);
    vertical-align: middle; /* Vertikální zarovnání na střed */
    display: inline-block; /* Pro zajištění správného chování */
  }
  </style>
  