<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <!-- Tlačítko pro otevření/zavření navigačního šuplíku na malých displejích -->
      <v-app-bar-nav-icon @click="drawer = !drawer" v-if="display.smAndDown"></v-app-bar-nav-icon>
      <v-spacer v-if="display.smAndDown"></v-spacer>

      <!-- Logo aplikace -->
      <router-link to="/" class="d-flex align-center" style="background-color:#FFFF; padding: 3px; margin-left: 5px; border-radius: 5px;" v-if="display.mdAndUp">
        <v-img :src="imageUrl" alt="Logo ROBISU" width="170"></v-img>
      </router-link>

      <v-spacer v-if="display.mdAndUp"></v-spacer>

      <!-- Hlavní navigační odkazy se skryjí na malých displejích -->
      <div v-if="display.mdAndUp">
        <v-btn text to="/">Kalendář soutěží</v-btn>
        <v-btn text to="/vysledky">Výsledky</v-btn>
        <v-btn text to="/clenove" v-if="isLoggedIn">Členové</v-btn>
        <v-btn text to="/oddil">Oddíly</v-btn>
        <v-btn text to="/napoveda">Nápověda</v-btn>
      </div>
      
      <!-- Menu Nástroje se skryje na malých displejích -->
      <v-menu offset-y transition="slide-y-transition" v-if="display.mdAndUp && isLoggedIn">
        <template v-slot:activator="{ props }">
          <v-btn v-if="isLoggedIn" text v-bind="props">
            Další <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item link to="/export"><v-list-item-title>Exporty</v-list-item-title></v-list-item>
          <v-list-item link to="/api"><v-list-item-title>API</v-list-item-title></v-list-item>
        </v-list>
      </v-menu>
      <v-spacer></v-spacer>
    
    <!-- Menu přihlášeného uživatele -->
    <v-menu transition="slide-y-transition" v-if="isLoggedIn">
      <template v-slot:activator="{ props }">
        <v-btn text v-bind="props">
          {{ firstName }} {{ last_name }} <span v-if="index && index !== 'null' && index !== ''">&nbsp;({{ index }})</span><span v-else>&nbsp;(neregistrovaný/á)</span> <v-icon right>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item :to="`/ucet/${userID}`">Můj profil</v-list-item>
        <v-list-item v-if="isLoggedIn && userClub && userClub !== 'null' && userClub !== ''" :to="`/oddil/${index.slice(0, 3)}`"><v-list-item-title>Můj oddíl</v-list-item-title></v-list-item>
        <!-- <v-list-item link to="/finance"><v-list-item-title>Moje finance</v-list-item-title></v-list-item> -->
        <v-list-item link to="/nastaveni"><v-list-item-title>Nastavení</v-list-item-title></v-list-item>
        <v-divider :thickness="2" class="border-opacity-25"></v-divider>
        <v-list-item @click="logout"><v-list-item-title>Odhlásit se</v-list-item-title></v-list-item>
      </v-list>
    </v-menu>
    <!-- Tlačítko pro přihlášení, pokud uživatel není přihlášený -->
    <v-btn dark to="/login" v-else>Přihlásit se</v-btn>


    </v-app-bar>

    <!-- Navigační šuplík pro malé displeje -->
    <v-navigation-drawer v-model="drawer" app fixed temporary>
      <v-list>
        <v-img :src="imageUrl" alt="Logo ROBISU" width="170" style="margin: 0px auto" ></v-img>
        <v-list-item link :to="'/'">
          <v-list-item-icon><v-icon>mdi-calendar-text</v-icon></v-list-item-icon>
          <v-list-item-title>Kalendář</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="'/vysledky'">
          <v-list-item-icon><v-icon>mdi-trophy</v-icon></v-list-item-icon>
          <v-list-item-title>Výsledky</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="'/clenove'" v-if="isLoggedIn">
          <v-list-item-icon><v-icon>mdi-account-group</v-icon></v-list-item-icon>
          <v-list-item-title>Členové</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="'/oddil'">
          <v-list-item-icon><v-icon>mdi-home-account</v-icon></v-list-item-icon>
          <v-list-item-title>Oddíly</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="'/export'" v-if="isLoggedIn">
          <v-list-item-icon><v-icon>mdi-export</v-icon></v-list-item-icon>
          <v-list-item-title>Exporty</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="'/api'">
          <v-list-item-icon><v-icon>mdi-api</v-icon></v-list-item-icon>
          <v-list-item-title>API</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="'/napoveda'">
          <v-list-item-icon><v-icon>mdi-help-circle</v-icon></v-list-item-icon>
          <v-list-item-title>Nápověda</v-list-item-title>
        </v-list-item>


        <!-- Další odkazy pro navigační šuplík -->
      </v-list>
    </v-navigation-drawer>

    <!-- Hlavní obsah aplikace -->
    <v-main>
      <router-view />
    </v-main>
    

    <v-footer style="align-items: flex-end">
    <v-row>
      <v-col class="text-center">
          <span style="font-weight: bold;">ROB</span>ácký <span style="font-weight: bold;">I</span>nformační <span style="font-weight: bold;">S</span>ystém - ROBIS, verze <router-link to="/verze">{{ latestVersion }}</router-link> beta, &copy;ROBis tým, info@rob-is.cz
      </v-col>
    </v-row>
  </v-footer>
    
  </v-app>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useAuthStore } from '/auth.js';
import { ref } from 'vue';
import { useDisplay } from 'vuetify';
import { latestVersion } from '@/composables/version.js';
const authStore = useAuthStore();
const { isLoggedIn, userID, userClub, firstName, last_name, index } = storeToRefs(authStore);
const display = ref(useDisplay());
const drawer = ref(false);
const logout = () => {
  authStore.logout();
};

const imageUrl = ref(process.env.VUE_APP_API_STATIC_URL + 'static/logo.png');
// Oprávnění organizátora, závisí na tom, jestli je organizátor ve stejném klubu jako event
// const isOrganizer = computed(() => {
//   // Převod Proxy objektu na běžné pole pro jednoduchý přístup
//   const rolesArray = Array.from(authStore.roles);
//   const isRoleOrganizer = rolesArray.includes('organizer');
//   return isRoleOrganizer;
// });


</script>




<style scoped>
  a:hover {
    color: inherit;
      }

      /* Skrýt na malých displejích */
  .hidden-sm-and-down {
    display: none;
  }

  @media (min-width: 960px) { /* MD breakpoint od Vuetify */
    .hidden-sm-and-down {
      display: block;
    }
  }
</style>


